*:focus {
  outline: none !important;
  box-shadow: none !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #20113e;
  border-radius: 10px;
  scrollbar-width: thin;
  scrollbar-color: #20113e;
}

.login__section {
  background: url("../src/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-color: #000;
}

.login__section .login__block {
  margin: auto;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 25rem;
  border-radius: 15px;
  overflow: hidden;
}

.login__section .login__block .loginform__block {
  padding: 2rem;
  margin-top: 2rem;
}

.login__section .login__block .loginform__block .heading {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.login__section .login__block .loginform__block .form-control.is-invalid {
  background-position: 85%;
}

.login__section .login__block .image__block {
  background: url("../src/login-img.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 25rem;
}

.blue__btn {
  background: #20113e !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  height: 35px !important;
  padding: 0 10px !important;
}

.linkText {
  color: #602acc !important;
  cursor: pointer !important;
}

.heading {
  color: #20113e;
  font-size: 24px;
  margin-bottom: 1rem;
}

.form__section .input__block {
  margin-bottom: 1rem;
}

.form__section .input__block input,
.form__section .input__block select {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 5px;
  color: #3b4452;
  height: 32px;
  opacity: 1;
  padding: 5px 10px;
  width: 100%;
}

/* .dashboard__logo {
width: 150px;
text-align: center;
margin: 20px auto;
}

.dashboard__logo img {
width:100px;
}

.ant-layout-sider-collapsed .dashboard__logo {
text-align: left;
}

.ant-layout-sider-collapsed .dashboard__logo img {
width:60px;
margin-left:5px;
}

.ant-layout .ant-layout-sider {
background: #20113e;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-menu-dark, 
:where(.css-dev-only-do-not-override-yp8pcc).ant-menu-dark>.ant-menu {

} */

.main__section {
  display: flex;
}

.main__section .sidebar {
  background: #20113e;
  height: auto;
  padding-top: 0;
  transition: all 0.5s;
  width: 15%;
}

.main__section .right__section {
  width: 85%;
  background: #f0f0f0;
}

.main__section .sidebar .dashboard__logo {
  width: 150px;
  text-align: center;
  margin: 20px auto;
}

.main__section .sidebar .dashboard__logo img {
  width: 100px;
}

.header__section {
  /* box-shadow: 1px 0px 9px 0px #ccc; */
  background: #fff;
  height: 40px;
}

.header__section .user__avtar {
  position: absolute;
  right: 10px;
  top: 10px;
}

.header__section .user__avtar i {
  font-size: 25px;
  color: #20113e;
  cursor: pointer;
}

.navbar__div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  height: 84vh;
}

.navbar-expand .navbar-nav .nav-link,
.navbar-expand .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-expand .navbar-nav .nav-link.active {
  background: #fff;
  color: #20113e;
}

.logout__btn {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.logout__btn button {
  color: #20113e !important;
  background: #fff !important;
  width: 100%;
  border: none;
}

.main__section .contain__area {
  background: #fff;
  margin: 1rem;
  padding: 1rem;
  height: calc(100vh - 73px);
}

.main__section .page__heading {
  font-size: 1.1rem;
  color: #20113e;
  font-weight: 600;
  margin-bottom: 2rem;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #efefef;
}

.ant-table-thead > tr > th img {
  vertical-align: middle;
  width: 14px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.ant-table-container
  table
  > thead
  > tr:first-child
  th.ant-table-cell-scrollbar:last-child {
  border-top-right-radius: 8px;
  box-shadow: none;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: #f0f0f0 !important;
}

.filter__section .upload__excel .form-control {
  display: inline-flex;
  width: 75%;
  padding: 0;
  height: 35px;
  margin-right: 10px;
  align-items: center;
}

.ant-picker-focused,
.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #bebfb6;
  box-shadow: none;
  opacity: 1;
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #20113e;
}

.upload__format {
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-width: 30rem;
  left: 0;
}

.upload__format table thead {
  background: #20113e;
}

.upload__format table th {
  padding: 5px;
  font-size: 12px;
  color: #fff;
}

.upload__format table td {
  padding: 5px;
  font-size: 12px;
}

.error-color-red {
  color: red !important;
  font-size: 12px;
  margin-top: 2px;
}
.error-color-red-left {
  display: block;
  text-align: left;
}
.margin-btn {
  margin-left: auto;
  margin-bottom: 1rem;
}
.d-modal {
  margin-top: 2rem;
}
.ant-modal-footer{
  display: flex;
  justify-content: center;
}
.d-modal label{
  padding-right: 25px;
}
